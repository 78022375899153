<template>
	<div class="hello">
		<el-container>
			<el-header>
				<span>雅帝乐预约核验系统</span>
				<div>
					<button @click="automatic">自动核验</button>
					<button class="btn1">手动核验</button>
				</div>
			</el-header>
			<el-container>
				<el-aside width="120px">
					<div class="he" @click="heyan" :class="{'hui':show}">核验</div>
					<div class="his" @click="his" :class="{'hui':!show}">历史记录</div>
				</el-aside>
				<el-main v-show="show">
					<div class="tops">
						<p class="title">快速核验</p>
						<div class="kuang">
							<input id="inputId" type="text" autocomplete="off" v-model="erweima">
							<!-- <button @click="hy_btn">查看套餐</button> -->
						</div>
					</div>
					<div class="f_box" v-if="box_show">
						<div class="bottoms">
							<div class="worker">
								<p class="title">员工信息</p>
								<div class="infos">
									<img :src='data.user.headimgurl' alt="">
									<div class="right"> 
										<text>姓名：{{data.user.name}}</text>
										<text>工号：{{data.user.worker_number}}</text>
										<text>部门：{{data.user.department_name}}</text>
									</div>
								</div>
							</div>
							<div class="rights">
								<p class="titles">预约信息</p>
								<div class="info" v-if='data.type == 1'>
									
									<div>
										<p class="name">{{data.product.name}}</p>
										<p class="infos">{{data.product.goods_str}}</p>
									</div>
								
								</div>
								<div class="info" v-else>
									<div v-for='item in data.product'>
										<p class="name">{{item.goods_name}} x {{item.total_nums}}</p>
										<p class="infos" v-if='item.prolist != underfind'>{{item.prolist.goods_str}}</p>
									</div>
								</div>
								<div class="btns">
									<button class="qu" @click="cancel">取消</button>
									<button @click="yes">核验</button>
								</div>
							</div>
							
							
						</div>
						
					</div>

				</el-main>
				<el-main v-show="!show">
					<div class="hiss">
						<div class="hiss_top">
							<text class='title'>历史记录</text>
							<el-select @change="changes" v-model="datevalue" placeholder="选择日期">
								<el-option v-for="item in options" :key="item" :label="item.label" :value="item">
								</el-option>
							</el-select>
						</div>
						<div class="hiss_center">
							<text class='time'>时间</text>
							<text class='user'>员工</text>
							<text class='number'>工号</text>
							<text class='department_name'>套餐名</text>
							<text class='goods_str'>具体菜品</text>
						</div>
						<div class="hiss_bottom" v-loading="loading">
							<div class="listOne" v-if="orderlist.length > 0" v-for="item in orderlist">
								<text class='time'>{{item.addtime}}</text>
								<text class='user'>{{item.username}}</text>
								<text class='number'>{{item.worker_number}}</text>
								<text class='department_name'>{{item.product_name}}X{{item.total_nums}}</text>
								<text class='goods_str'>{{item.goods_str}}</text>
								<text class='btns' @click='detail(item.goods_str)'>查看详情</text>
							</div>
							
							<div class="none" v-else>---暂无数据---</div>
						</div>
					</div>
					<div class="btn_page">
						<text class='lf' @click='shang'>上一页</text>
						<text class='rg' @click='xia'>下一页</text>
					</div>
				</el-main>
			</el-container>
		</el-container>
		<el-dialog v-model="centerDialogVisible" title="详情" width="30%" center>
		    <span>{{detail_goods}}</span>
		    <template #footer>
		      <span class="dialog-footer">
		        <el-button type="primary" @click="centerDialogVisible = false"
		          >确定</el-button
		        >
		      </span>
		    </template>
		  </el-dialog>
		<div class="mask" v-if="box_show"></div>
	</div>
</template>

<script>
	// 引入axios
	import axios from 'axios';
	// 引入端口配置文件
	import API_LIST from '../../list.config.js';
	import qs from 'qs'
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'


	export default {
		name: "HelloWorld",
		props: {
			msg: String,

		},
		data() {
			return {
				show: true,
				box_show:false,
				erweima: '', // 输入框内的信息
				data: {
					product: [],
					user: {},
				}, // yu
				dialogVisible: false,
				message: '',
				date: '',
				page: 1,
				device_id: null,
				canteen_id: null,
				type: null,
				options: [],
				datevalue: '',
				orderlist: [],
				arr: ['111', '222', '333', '444', '555', '666', '777', '888', '999'],
				detail_goods:'',
				centerDialogVisible:false, // 模态框
				loading:false
			}

		},
		watch: {

			erweima(newerweima, olderweima) {
				const that = this;
				console.log(newerweima)
				if (newerweima.length > 3) {
					this.arr.forEach(element => {
						if (element == newerweima.substring(newerweima.length - 3)) {
							console.log('我输入完了')
							let str = newerweima.substring(0, newerweima.length - 3);
							
							that.box_show = true;
							
							that.hy_btn(str)
						}
					})
				}


			}
		},
		created() {
			var query = this.$route.query;
			this.device_id = query.device_id;
			this.canteen_id = query.canteen_id;
			this.date = this.format(Date.parse(new Date()))
			console.log(this.date)
		},
		mounted() {
			document.getElementById('inputId').focus()
		},
		methods: {
			// 跳转到自动核验
			automatic(){
				this.$router.push({
					path: '/',
					query: {
						device_id:this.device_id,
						canteen_id:this.canteen_id,
					}
				});
			},
			heyan() {
				this.page = 1;
				this.show = true;
				this.datevalue = '';
				setTimeout(function() {
					document.getElementById('inputId').focus()
				}, 500)

				// this.focus_btn()


			},
			// 核验历史
			his() {
				console.log(this.show)
				// this.page = 1;
				this.loading = true;
				document.getElementById('inputId').blur()
				this.show = false;
				let obj = {
					device_id: this.device_id,
					canteen_id: this.canteen_id,
					date: this.date,
					page: this.page
				}
				let a = qs.stringify(obj)
				console.log(a)

				axios.post(API_LIST.history_prev_day3, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						console.log(_d)
						this.loading = false;
						if (_d.data.status == 200) {
							this.total_page = _d.data.list.page; // 总页数
							this.options = _d.data.list.date;
							this.orderlist = _d.data.list.return_data;
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})

						}
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			// 点击上一页
			shang(){
				const that = this;
				if(that.page>1){
					that.page--;
					that.his()
				}else{
					ElMessage({
						message: '已经是第一页了',
						type: 'warning',
					})
				}
			},
			// 点击下一页
			xia(){
				const that = this;
				if(that.page<that.total_page){
					that.page++;
					that.his()
				}else{
					ElMessage({
						message: '已经是最后一页了',
						type: 'warning',
					})
				}
			},
			// 核验
			hy_btn(str) {

				let obj = {
					foods: str,
					device_id: this.device_id,
					canteen_id: this.canteen_id,
				}
				let a = qs.stringify(obj)
				console.log(a)

				axios.post(API_LIST.selelct_order, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						console.log(_d)
						
						document.getElementById('inputId').blur()
						if (_d.data.status == 200) {

							this.data = _d.data.list
							this.erweima = ''
							// this.yes()
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})
							this.erweima = ''
							this.data = {
								product: [],
								user: {},
							}
						}
					})
					.catch(function(error) {
						console.log(error);
					});


			},
			// 取消
			cancel() {
				this.data = {
					product: [],
					user: {},
				}
				this.box_show = false;
				this.focus_btn();
			},
			// 确定
			yes() {
				const that = this;
				if (this.data.order_id == '') {
					return false;
				}
				let Base64 = require('js-base64').Base64;
				let aaa = Base64.encode(this.data.user.id)
				let obj = {
					order_id: this.data.order_id,
					type: this.data.type,
					u_id: aaa,
					device_id: this.device_id,
					canteen_id: this.canteen_id,
				}
				let a = qs.stringify(obj)
				console.log(a)

				axios.post(API_LIST.check_order, obj, {
						headers: {
							'Content-Type': 'application/json'
						} //加上这个
					})
					.then(_d => {
						console.log(_d)
						that.focus_btn()
						this.box_show = false;
						if (_d.data.status == 200) {
							
							ElMessage({
								message: '核验完成',
								type: 'success',
							})
							this.data = {
								product: [],
								user: {},
							}
						} else {
							ElMessage({
								message: _d.data.msg,
								type: 'warning',
							})
							this.erweima = ''
							this.data = {
								product: [],
								user: {},
							}
						}
					})
					.catch(function(error) {
						console.log(error);
					});
			},
			// 
			add0(m) {
				return m < 10 ? '0' + m : m
			},
			format(shijianchuo) {
				//shijianchuo是整数，否则要parseInt转换
				var time = new Date(shijianchuo);
				var y = time.getFullYear();
				var m = time.getMonth() + 1;
				var d = time.getDate();
				var h = time.getHours();
				var mm = time.getMinutes();
				var s = time.getSeconds();
				return y + '-' + this.add0(m) + '-' + this.add0(d);
			},
			// 获取光标
			focus_btn() {
				document.getElementById('inputId').focus()
			},
			// 
			changes(){
				console.log(111)
				const that = this;
				this.page = 1;
				that.date = that.datevalue;
				that.his()
			},
			// 点击查看详情
			detail(e){
				console.log(e)
				
				this.centerDialogVisible = true;
				this.detail_goods = e;
			}


		}
	};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	.hello {
		height: 100%;
	}
	.mask{
		width: 100%;
		height: 100%;
		background-color: #000;
		opacity: 0.3;
		left: 0;
		top: 0;
		position: fixed;
	}

	.el-header,
	.el-footer {
		width: 100%;
		height: 60px;
		background: #EF4248;
		color: #fff;
		box-shadow: 0px 5px 10px 0px rgba(9, 7, 35, 0.18);
		text-align: left;
		line-height: 60px !important;
		padding: 0 200px;
		font-size: 20px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		div{
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			button{
				width: 80px;
				height: 25px;
				border-radius: 40px;
				margin: 0 0 0 10px;
				color: #fff;
				background-color: #EF4248;
			}
			.btn1{
				color: #EF4248;
				background-color: #fff;
			}
		}
	}

	.el-aside {
		background-color: #F0F5F9;
		color: var(--el-text-color-primary);
		text-align: center;
	}

	.el-main {
		background-color: #fff;
		color: var(--el-text-color-primary);
		text-align: center;
		line-height: 160px;
		position: relative;
	}

	.el-container.is-vertical {
		height: 100%;
	}

	body>.el-container {
		margin-bottom: 40px;

	}

	.el-container:nth-child(5) .el-aside,
	.el-container:nth-child(6) .el-aside {
		line-height: 260px;
	}

	.el-container:nth-child(7) .el-aside {
		line-height: 320px;
	}

	.hui {
		background-color: #DCE0E9 !important;
	}

	.he,
	.his {
		padding: 20px 0;
		font-size: 20px;
	}

	.tops {
		display: flex;
		flex-direction: row;
		background-color: #fff;
		box-sizing: border-box;
		padding: 20px;
		align-items: center;
		.title {
			font-size: 20px;
			color: #090723;
			text-align: left;
			border-left: 2px solid #D90019 !important;
			height: 20px;
			line-height: 20px;
			padding: 0 0 0 5px;
		}

		.kuang {
			display: flex;
			flex-direction: row;
			margin: 0px 0 0 40px;

			input {
				width: 700px;
				height: 50px;
				font-size: 20px;
				padding: 0 0 0 10px;
				border: 1px solid #ccc;
				border-radius: 5px;
			}

			button {
				width: 160px;
				height: 50px;
				background: #EF4248;
				border: 1px solid #DDE0E7;
				border-radius: 6px;
				color: #fff;
				font-size: 20px;
				font-weight: 400;
				margin: 0 0 0 20px;
			}
		}
	}
	.f_box{
		position: absolute;
		left: 100px;
		top: 20px;
		z-index: 100;
		display: flex;
		flex-direction: row;
		width: 658px;
		height: 561px;
		
		.bottoms {
			width: 100%;
			display: flex;
			flex-direction: row;
			background-color: #fff;
			box-sizing: border-box;
			padding: 20px;
			margin: 10px 0 0 0;
			border-radius: 10px;
			.titles {
				font-size: 20px;
				color: #090723;
				text-align: left;
				border-left: 2px solid #D90019 !important;
				height: 20px;
				line-height: 20px;
				padding: 0 0 0 5px;
				margin: 0 0 0 0;
			}
			.info {
				margin: 0 0 0 40px;
				height: 420px;
				overflow-y: scroll;
				
		
				.name,
				.infos {
					
					font-size: 20px;
					color: #090723;
					text-align: left;
					height: 20px;
					line-height: 20px;
					margin: 20px 0 0 0;
		
					text {
						margin-right: 5px;
					}
				}
			}
		
			.worker {
				margin: 10px 0 0 0;
				display: flex;
				flex-direction: column;
				.title {
					font-size: 20px;
					color: #090723;
					text-align: left;
					border-left: 2px solid #D90019 !important;
					height: 20px;
					line-height: 20px;
					padding: 0 0 0 5px;
				}
		
				.infos {
					display: flex;
					flex-direction: column;
					margin: 20px 0 0 0;
		
					img {
						width: 140px;
						height: 176px;
						border-radius: 10px;
					}
		
					.right {
						display: flex;
						margin: 20px 10px 0;
						flex-direction: column;
						justify-content: flex-start;
						line-height: 40px;
						font-size: 20px;
		
						text {
							text-align: left;
						}
					}
				}
			}
		
		
		}
		
		.btns {
			background-color: #fff;
			padding: 20px;
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
		
			button {
				width: 160px;
				height: 50px;
				background: #EF4248;
				border: 1px solid #DDE0E7;
				border-radius: 6px;
				color: #fff;
				margin-left: 20px;
				font-size: 20px;
			}
		
			.qu {
				background: #B9BDC6 !important;
			}
		}
	}
	

	.hiss {
		background-color: #fff;
		box-sizing: border-box;
		padding: 20px;
		line-height: 23px;

		.hiss_top {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			font-size: 20px;
			border-bottom: 1px solid #ccc;
			padding-bottom: 20px;

			.title {
				border-left: 2px solid #D90019 !important;
				height: 20px;
				padding: 0 0 0 5px;
			}

			.date {
				border: 1px solid #ccc;
				width: 130px;
				height: 30px;
				line-height: 30px;
				text-align: left;
				padding: 0 0 0 5px;
				position: relative;

			}

			.date::after {
				position: absolute;
				right: 8px;
				top: 5px;
				content: '';
				width: 10px;
				height: 10px;
				border-right: 1px solid #ccc;
				border-bottom: 1px solid #ccc;
				transform: rotate(45deg);
			}

		}

		.hiss_center {
			display: flex;
			flex-direction: row;
			font-size: 20px;
			background-color: #F0F5F9;
			padding: 10px;
			margin: 20px 0;
			text-align: left;

			.time {
				width: 15%;
			}

			.user {
				width: 20%;
			}

			.number {
				width: 15%;
			}

			.department_name {
				width: 20%;
			}

			.goods_str {
				width: 30%;
			}

		}

		.hiss_bottom {
			display: flex;
			flex-direction: column;
			font-size: 20px;
			background-color: #F0F5F9;
			padding: 10px;
			text-align: left;

			.listOne {
				width: 100%;
				display: flex;
				flex-direction: row;
				margin-bottom: 16px;

				.time {
					width: 15%;
				}

				.user {
					width: 20%;
				}

				.number {
					width: 15%;
				}

				.department_name {
					width: 20%;
				}

				.goods_str {
					width: 20%;
					overflow: hidden;
					height: 20px;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
				.btns{
					width: 8%;
					height: 25px;
					background-color:#F22C40;
					color: #fff;
					font-size: 15px;
					text-align: center;
					border-radius: 50px;
				}


			}

		}


	}

	.btn_page {
		background-color: #fff;
		color: #F22C40;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding: 0 20px;
		line-height: 30px;

		.lf,
		.rg {
			font-size: 15px;
			width: 80px;
			position: relative;
			background-color: #eee;
			border-radius: 10px;
			padding: 5px 10px;
		}

		.lf::before {
			position: absolute;
			left: 10px;
			top: 15px;
			content: '';
			width: 7px;
			height: 7px;
			border-right: 1px solid #F22C40;
			border-bottom: 1px solid #F22C40;
			transform: rotate(135deg);

		}

		.rg::after {
			position: absolute;
			right: 10px;
			top: 15px;
			content: '';
			width: 7px;
			height: 7px;
			border-right: 1px solid #F22C40;
			border-bottom: 1px solid #F22C40;
			transform: rotate(-45deg);
		}
	}
	.none{
		text-align: center;
		font-size: 20px;
		color: #ccc;
	}
</style>
